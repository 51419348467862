import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
// import './styles/css/index.css';
// import registerServiceWorker from './registerServiceWorker';
//import Login from './Login';
import App from "./app";

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
//ReactDOM.render(<Login />, document.getElementById('root'));

// registerServiceWorker();
