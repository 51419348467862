import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import React from "react";
import "./custom-task-form.css";
export default class CustomTaskForm extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    key: "",
    label: "",
    type: "",
    level: "",
    isMandatory: false,
  };

  c;

  handleSubmit = (e) => {
    e.preventDefault();
    // method,responseType,url,data
    const data = {
      key: this.state.key,
      label: this.state.label,
      projectId: this.props.projectId,
      type: this.state.type,
      level: this.state.level,
      isMandatory: this.state.isMandatory,
    };
    ServiceRequest(
      "post",
      "json",
      serviceHost + "/projects/addCustomTaskField",
      data
    )
      .then((response) => {
        console.log(response);
        if (response) {
          this.props.setCustomTasksField((prev) => {
            return [...prev, response.data.data];
          });
        }
      })
      .catch((error) => {
        alert("Duplicate Key");
      });
    // window.location.reload()
  };

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState({
      [name]: type === "checkbox" ? checked : value,
    });
  };

  render() {
    return (
      <div id="form-container">
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label for="level">Level</label>
          <select
            id="level"
            placeholder="Level"
            value={this.state.level || ""}
            onChange={(e) => {
              const value = e.target.value;
              this.setState((prev) => {
                return { ...prev, level: value };
              });
            }}
            required
          >
            <option value={""}>select</option>
            <option value={"project"}>Project</option>
            <option value={"task"}>Task</option>
          </select>

          <label for="key">Key</label>
          <input
            id="key"
            type="text"
            placeholder="key"
            onChange={(e) => {
              const value = e.target.value;
              this.setState((prev) => {
                return { ...prev, key: value };
              });
            }}
          ></input>

          <label for="label">Label</label>
          <input
            id="label"
            type="text"
            placeholder="label"
            value={this.state.label || ""}
            onChange={(e) => {
              const value = e.target.value;
              this.setState((prev) => {
                return { ...prev, label: value };
              });
            }}
          ></input>

          <label for="type">Type</label>
          <select
            id="type"
            placeholder="type"
            value={this.state.type || ""}
            onChange={(e) => {
              const value = e.target.value;
              this.setState((prev) => {
                return { ...prev, type: value };
              });
            }}
            required
          >
            <option value={""}>select</option>
            <option value={"number"}>Number</option>
            <option value={"text"}>Text</option>
          </select>

          <div className="inline-container">
            <label htmlFor="isMandatory">Is Mandatory</label>
            <input
              id="isMandatory"
              name="isMandatory"
              type="checkbox"
              checked={this.state.isMandatory}
              onChange={this.handleChange}
            />
          </div>

          <button type="submit">Add Field</button>
        </form>
      </div>
    );
  }
}
