module.exports = Object.freeze({
  //domain:[ "@algorisys.com","@gmail.com"],
  Export: false,
  showIsMiscellaneous: true,
  projectStatusCheck: ["inprogress", "new", "onHold"],
  monthCount: 3,
  maxInprogressTaskCount: 2,
  // socketPath: '/'
  storyPoint: "1 SP = 1 hr",
  minStoryPonit: "1",
  delayTime: 50,
  // sitekey: '6LezHHgUAAAAAPTt97gKwfO3H6iDO3LJy4DvVw1R', old
  sitekey: "6LdnHPQpAAAAACr157uzyCu9ptx4S1tq8hPt0ZVp", //new
  profileUrl: "/api/ProfilePic/",
  defaultProjectName: "DefaultProject",
});
