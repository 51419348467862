import React from 'react'
import CustomTaskForm from './custom-task-form'
import CustomTaskList from './custom-task-list'
import { useState } from 'react'

const CustomTask = ({projectId}) => {

    const [customTasksField, setCustomTasksField] = useState([])
    
  return (
    <div className='flex'>

<CustomTaskForm projectId={projectId} customTasksField={customTasksField}  setCustomTasksField= {setCustomTasksField} />
<CustomTaskList projectId={projectId} customTasksField={customTasksField}  setCustomTasksField= {setCustomTasksField} />
    </div>
  )
}

export default CustomTask