import React, { Component } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Setup localizer
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date()),
  getDay,
  locales,
});

export default class ProjectsCalendarView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarStyle: this.getCalendarStyle(window.innerWidth),
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ calendarStyle: this.getCalendarStyle(window.innerWidth) });
  };

  getCalendarStyle = (width) => {
    if (width < 480) {
      return { height: 700, width: 360, marginLeft: 20 };
    } else if (width < 768) {
      return { height: 600, width: 550, marginLeft: 20 };
    } else if (width < 1200) {
      return { height: 700, width: 950, marginLeft: 20 };
    } else {
      return { height: 700, width: 1200, marginLeft: 20 };
    }
  };

  render() {
    const { projects } = this.props;

    // Map projects to event format for react-big-calendar
    const events = projects.map((p) => ({
      id: p._id,
      start: new Date(p.startdate),
      end: new Date(p.enddate),
      title: p.title,
      url: "/project/tasks/" + p._id,
    }));

    return (
      <div
        id="largeCalendar"
        //className="project-list"
      >
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          //style={{ height: 700, width: 1200, marginLeft: 20 }}
          style={this.state.calendarStyle}
          views={["month", "week", "day"]}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: "#3174ad",
              color: "white",
            },
          })}
          onSelectEvent={(event) => {
            window.location.href = event.url;
          }}
        />
      </div>
    );
  }
}

// import React,{Component} from 'react';
// import FullCalendar from 'fullcalendar-reactwrapper';

// export default class ProjectsCalendarView extends Component {
//     render() {
//         let projects = this.props.projects.map((p) => {
//             let project = {
//                 id:p._id,
//                 start:p.startdate,
//                 end:p.enddate,
//                 title:p.title,
//                 url:"/project/tasks/"+ p._id
//             }
//             return project;
//         })
//         return(
//         <div id="largeCalendar" className="project-list">
//            <FullCalendar
//                 header = {{
//                     left: 'prev,next today myCustomButton',
//                     center: 'title',
//                     right: 'month,basicWeek,basicDay'
//                 }}
//                 defaultDate= {new Date()}
//                 navLinks= {true} // can click day/week names to navigate views
//                 editable= {true}
//                 eventLimit= {true} // allow "more" link when too many events
//                 events = {projects}
//             />
//         </div>
//         )
//     }
// }
