import React, { useEffect, useState } from "react";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import "./edit-taskfield.css";

const EditForm = ({ customFieldId, setIsEdit, setCustomTasksField }) => {
  const [customFieldData, setCustomFieldData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (customFieldId) {
      ServiceRequest(
        "get",
        "json",
        `${serviceHost}/projects/getCustomTaskField/${customFieldId}`,
        ""
      )
        .then((response) => {
          setCustomFieldData(response.data.customTaskField);
        })
        .catch((error) => {
          console.error(error);
          // Handle errors here, e.g., redirect back to the list
        });
    }
  }, [customFieldId]);

  // const handleChange = (event) => {
  //   setCustomFieldData({ ...customFieldData, [event.target.name]: event.target.value });
  //   setErrors({ ...errors, [event.target.name]: '' }); // Clear error on change
  // };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCustomFieldData({
      ...customFieldData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { key, label, type, _id } = customFieldData;
    let validationErrors = {};

    if (!key) {
      validationErrors.key = "Key is required";
    }
    if (!label) {
      validationErrors.label = "Label is required";
    }
    if (!type) {
      validationErrors.type = "Type is required";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await ServiceRequest(
          "put",
          "json",
          `${serviceHost}/projects/updateCustomTaskField/${customFieldId}`,
          customFieldData
        );
        if (response.status === 200) {
          // Handle successful update
          console.log("Custom field updated successfully");
          // setCustomTasksField((prev)=> {
          //   let index = prev.findIndex((p)=> p._id == _id)
          //   if(index!=-1){
          //     prev[index].key = key
          //     prev[index].label = label
          //     prev[index].type = type
          //   }
          //   return prev
          // })
          setCustomTasksField((prev) => {
            let index = prev.findIndex((p) => p._id === _id);
            if (index !== -1) {
              prev[index] = customFieldData;
            }
            return [...prev];
          });
          setIsEdit(false);

          // Redirect back to the list component with project ID
        } else {
          console.error("Update failed:", response);
          // alert(response.message)
          console.log(response);
          // Handle update failure here, e.g., display an error message
        }
      } catch (error) {
        console.log(error);
        alert("Duplicate Key");

        // Handle errors here
      }
    }
  };

  return (
    <div class="edit-custom-field-container">
      <h2>Edit Custom Field</h2>
      <form onSubmit={handleSubmit}>
        <div class="form-group">
          <label for="key">Key:</label>
          <input
            type="text"
            name="key"
            id="key"
            value={customFieldData.key || ""}
            onChange={handleChange}
            required
          />
          {errors.key && <span class="error">{errors.key}</span>}
        </div>
        <div class="form-group">
          <label for="label">Label:</label>
          <input
            type="text"
            name="label"
            id="label"
            value={customFieldData.label || ""}
            onChange={handleChange}
            required
          />
          {errors.label && <span class="error">{errors.label}</span>}
        </div>
        <div class="form-group">
          <label for="type">Type:</label>
          <select
            name="type"
            id="type"
            value={customFieldData.type || ""}
            onChange={handleChange}
            required
          >
            <option value="text">Text</option>
            <option value="number">Number</option>
          </select>
          {errors.type && <span class="error">{errors.type}</span>}
        </div>
        <div className="form-group inline-container">
          <label htmlFor="isMandatory">Is Mandatory</label>
          <input
            id="isMandatory"
            name="isMandatory"
            type="checkbox"
            checked={customFieldData.isMandatory || false}
            onChange={handleChange}
          />
        </div>
        <div class="form-actions">
          <button type="submit" class="save-btn">
            Save Changes
          </button>
          <button
            type="button"
            class="cancel-btn"
            onClick={() => setIsEdit("")}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditForm;
