import React, { useState, useEffect } from "react";
import ProjectCustomTask from "../../components/custom-field/project-custom-task";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";

const CustomTaskField = () => {
  const [projects, setProjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await ServiceRequest(
          "get",
          "json",
          `${serviceHost}/projects/getAllProjectsId/all`
        );
        console.log("all projects", response);
        if (response && response.data && response.data.projects) {
          setProjects(response.data.projects);
        } else {
          setErrorMessage("Invalid response structure");
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        setErrorMessage("Error fetching projects: " + error.message);
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  console.log(projects, "projects");

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 content-wrapper">
          <div className="tab-content" id="projectTabs">
            <div
              role="tabpanel"
              className="tab-pane active"
              id="customTaskForm"
            >
              <ProjectCustomTask projects={projects} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTaskField;
