import React from "react";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import "./project-type-form.css";

export default class ProjectTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: this.state.type,
    };

    // Sending POST request to create project type
    ServiceRequest(
      "post",
      "json",
      serviceHost + "/projects/createProjectType",
      data
    )
      .then((response) => {
        console.log(response);
        if (response) {
          alert("Project type created successfully");
          this.props.onProjectTypeCreated(response.data.data);
        }
      })
      .catch((error) => {
        alert("Error creating project type: " + error.message);
      });
  };

  render() {
    return (
      <div id="form-container">
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="type">Type</label>
          <input
            id="type"
            type="text"
            placeholder="Type"
            value={this.state.type}
            onChange={(e) => this.setState({ type: e.target.value })}
            required
          />
          <button type="submit">Create Project Type</button>
        </form>
      </div>
    );
  }
}
