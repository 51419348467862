import React from "react";
import * as uploadservice from "../../services/upload/upload-service";
import * as ObjectId from "../../utils/mongo-objectid";
import "./user-profile.css";

export default class UploadTasksFile extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   uploadFiles: [],
    //   message: "",
    // };
    // this.handleFileChange = this.handleFileChange.bind(this);
    // this.uploadFile = this.uploadFile.bind(this);
  }

  render() {
    // const { uploadFiles, message } = this.state;

    return (
      <div className="upload-tasks-file">
        <div className="form-group">
          <label htmlFor="uploadFiles" style={{ fontWeight: "bold" }}>
            Upload Files
          </label>
          <input
            type="file"
            name="uploadFiles"
            multiple
            onChange={this.props.handleFileChange}
            className="form-control"
          />
        </div>
        {/* {message && <div className="alert alert-info">{message}</div>} */}
        <div>
          {/* {uploadFiles.map((fileWrapper, index) => (
            <div key={index}>
              <div>Filename: {fileWrapper.filename}</div>
              <div>Created By: {fileWrapper.createdBy}</div>
              <div>
                Created On: {new Date(fileWrapper.createdOn).toLocaleString()}
              </div>
            </div>
          ))} */}
        </div>
      </div>
    );
  }
}

// import React from "react";
// import * as uploadservice from "../../services/upload/upload-service";
// import * as ObjectId from "../../utils/mongo-objectid";
// import "./user-profile.css";

// export default class UploadTasksFile extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       uploadFiles: [],
//       message: "",
//     };
//     this.handleFileChange = this.handleFileChange.bind(this);
//     this.uploadFile = this.uploadFile.bind(this);
//   }

//   async handleFileChange(event) {
//     const files = Array.from(event.target.files);
//     console.log("Selected files:", files);

//     const uploadFiles = files.map((file) => ({
//       file,
//       filename: file.name,
//       isDeleted: false,
//       _id: ObjectId.mongoObjectId(),
//       createdBy: this.props.currentUserId || "User",
//       createdOn: new Date().toISOString(),
//     }));

//     this.setState({ uploadFiles });

//     if (this.props.onFileUpload) {
//       this.props.onFileUpload(uploadFiles);
//     }

//     for (let fileWrapper of uploadFiles) {
//       await this.uploadFile(fileWrapper);
//     }
//   }

//   async uploadFile(fileWrapper) {
//     const { file, _id, filename } = fileWrapper;
//     const formData = new FormData();
//     const fileName = filename.split(".");
//     const extension = fileName[fileName.length - 1];
//     const d = new Date();
//     const dateTime =
//       d.getFullYear() +
//       "-" +
//       (d.getMonth() + 1) +
//       "-" +
//       d.getDate() +
//       "_" +
//       d.getHours() +
//       "-" +
//       d.getMinutes() +
//       "-" +
//       d.getSeconds();
//     const name = fileName[0] + "_" + dateTime + "." + extension;

//     formData.append("uploadFile", file);
//     formData.append("filename", name);
//     formData.append("_id", _id);

//     for (let [key, value] of formData.entries()) {
//       console.log(`${key}:`, value);
//     }

//     let { response, err } = await uploadservice.postFile(formData);
//     if (err) {
//       this.setState({ message: err });
//     } else if (response.data.error) {
//       this.setState({ message: response.data.error });
//     } else {
//       this.setState((prevState) => {
//         const updatedFiles = prevState.uploadFiles.map((f) => {
//           if (f._id === _id) {
//             return {
//               ...f,
//               _id: response.data.result._id,
//               filename: response.data.result.filename,
//               isDeleted: response.data.result.isDeleted,
//               createdBy: response.data.result.createdBy,
//               createdOn: response.data.result.createdOn,
//             };
//           }
//           return f;
//         });
//         console.log("uploadFiles.....", updatedFiles);
//         return {
//           message: "File uploaded successfully",
//           uploadFiles: updatedFiles,
//         };
//       });
//       console.log("Upload success response:", response.data.result);
//     }
//   }

//   render() {
//     const { uploadFiles, message } = this.state;

//     return (
//       <div className="upload-tasks-file">
//         <div className="form-group">
//           <label htmlFor="uploadFiles" style={{ fontWeight: "bold" }}>
//             Upload Files
//           </label>
//           <input
//             type="file"
//             name="uploadFiles"
//             multiple
//             onChange={this.handleFileChange}
//             className="form-control"
//           />
//         </div>
//         {message && <div className="alert alert-info">{message}</div>}
//         <div>
//           {uploadFiles.map((fileWrapper, index) => (
//             <div key={index}>
//               <div>Filename: {fileWrapper.filename}</div>
//               <div>Created By: {fileWrapper.createdBy}</div>
//               <div>
//                 Created On: {new Date(fileWrapper.createdOn).toLocaleString()}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     );
//   }
// }
