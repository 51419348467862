import React from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Setup localizer
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date()),
  getDay,
  locales,
});

const TasksCalendarView = React.memo((props) => {
  console.log("TasksCalendarView props:", props);

  let tasksWithDate = props.tasks.filter((t) => {
    console.log("Task:", t);
    return t.startDate && t.endDate;
  });

  console.log("tasksWithDate:", tasksWithDate);

  // let events =
  //   tasksWithDate.length > 0 &&
  //   tasksWithDate.map((d) => {
  //     return {
  //       id: d._id,
  //       start: new Date(d.startDate),
  //       end: new Date(d.endDate),
  //       title: d.title,
  //     };
  //   });
  let events =
    tasksWithDate.length > 0
      ? tasksWithDate.map((d) => ({
          id: d._id,
          start: new Date(d.startDate),
          end: new Date(d.endDate),
          title: d.title,
        }))
      : [];

  console.log("events for react-big-calendar:", events);

  return (
    <div id="largeCalendar" className="task-list">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 570 }}
      />
    </div>
  );
});

export default TasksCalendarView;

// import React from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";

// const localizer = momentLocalizer(moment);

// const TasksCalendarView = React.memo((props) => {
//   console.log("TasksCalendarView props:", props);

//   let tasksWithDate = props.tasks.filter((t) => {
//     console.log("Task:", t);
//     return t.startDate && t.endDate;
//   });

//   console.log("tasksWithDate:", tasksWithDate);

//   let events =
//     tasksWithDate.length > 0 &&
//     tasksWithDate.map((d) => {
//       return {
//         id: d._id,
//         start: new Date(d.startDate),
//         end: new Date(d.endDate),
//         title: d.title,
//       };
//     });

//   console.log("events for react-big-calendar:", events);

//   return (
//     <div id="largeCalendar" className="task-list">
//       <Calendar
//         localizer={localizer}
//         events={events}
//         startAccessor="start"
//         endAccessor="end"
//         style={{ height: 500 }}
//       />
//     </div>
//   );
// });

// export default TasksCalendarView;

// import React from "react";
// import FullCalendar from "fullcalendar-reactwrapper";

// //export default class TasksCalendarView extends Component {
// const TasksCalendarView = React.memo((props) => {
//   // const TasksCalendarView = (props) => {
//   console.log("TasksCalendarView props:", props);

//   let tasksWithDate = props.tasks.filter((t) => {
//     console.log("Task:", t);
//     return (
//       t.startDate &&
//       t.endDate &&
//       t.endDate &&
//       t.startDate !== undefined &&
//       t.endDate !== undefined
//     );
//   });

//   console.log("tasksWithDate:", tasksWithDate);

//   let tasks =
//     tasksWithDate.length > 0 &&
//     tasksWithDate.map((d) => {
//       let task = {
//         id: d._id,
//         start: d.startDate,
//         end: d.endDate,
//         title: d.title,
//         // url:"/project/tasks/"+ t._id
//       };
//       console.log("Formatted task:", task);
//       return task;
//     });

//   console.log("tasks for FullCalendar:", tasks);

//   return (
//     <div id="largeCalendar" className="task-list">
//       <FullCalendar
//         header={{
//           left: "prev,next today myCustomButton",
//           center: "title",
//           right: "month,basicWeek,basicDay",
//         }}
//         defaultDate={new Date()}
//         navLinks={true} // can click day/week names to navigate views
//         editable={true}
//         eventLimit={true} // allow "more" link when too many events
//         events={tasks}
//       />
//     </div>
//   );
// });

// export default TasksCalendarView;
