import React, { useEffect, useState } from "react";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import EditForm from "./edit-taskfield-form";
import "./custom-task-list.css";

const CustomTaskList = ({
  projectId,
  setCustomTasksField,
  customTasksField,
}) => {
  const [isEdit, setIsEdit] = useState("");

  useEffect(() => {
    console.log("useEffect custom task list");
    console.log(projectId);

    ServiceRequest(
      "get",
      "json",
      `${serviceHost}/projects/getCustomTasksField/${projectId}`,
      ""
    )
      .then((response) => {
        console.log(response);
        setCustomTasksField(response.data.customTasksField);
      })
      .catch((error) => {
        console.error(error);
        // Handle errors here, e.g., display an error message to the user
      });
  }, [projectId]);

  const handleEdit = (customFieldId) => {
    // Handle edit functionality here, e.g., redirect to an edit form
    setIsEdit(customFieldId);
    console.log(`Edit custom field: ${customFieldId}`);
  };

  const handleDelete = async (customFieldId) => {
    if (window.confirm("Are you sure you want to delete this field?")) {
      try {
        const response = await ServiceRequest(
          "delete",
          null,
          `${serviceHost}/projects/deleteCustomTaskField/${customFieldId}`,
          ""
        );
        if (response.status === 200) {
          setCustomTasksField(
            customTasksField.filter((field) => field._id !== customFieldId)
          ); // Update state after successful deletion
        } else {
          console.error("Delete failed:", response);
          // Handle delete failure here, e.g., display an error message
        }
      } catch (error) {
        console.error(error);
        // Handle errors here
      }
    }
  };

  return (
    <div class="task-table-container">
      <table class="task-table">
        <tr>
          <th>Project</th>
          <th>Level</th>
          <th>Key</th>
          <th>Label</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
        {customTasksField.map((c) => (
          <tr key={c._id}>
            <td>{c.projectId.title}</td>
            <td>{c.level}</td>
            <td>{c.key}</td>
            <td>{c.label}</td>
            <td>{c.type}</td>
            <td>
              <button class="edit-btn" onClick={() => handleEdit(c._id)}>
                <i className="fas fa-edit"></i>
              </button>
              <button class="delete-btn" onClick={() => handleDelete(c._id)}>
                <i className="fas fa-trash-alt"></i>
              </button>
              {isEdit == c._id && (
                <EditForm
                  customFieldId={c._id}
                  setIsEdit={setIsEdit}
                  setCustomTasksField={setCustomTasksField}
                />
              )}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default CustomTaskList;
