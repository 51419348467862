import React, { useState } from "react";
import ProjectCustomTaskForm from "./project-custom-task-form";
import CustomTaskList from "./custom-task-list";
import { Link } from "react-router-dom";

const ProjectCustomTask = ({ projects }) => {
  const [customTasksField, setCustomTasksField] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  return (
    <div className="flex">
      {/* <div className="col-sm-12 pr-1"> */}
      {/* <h1 className="project-total mt-2 text-center"> Custom Fields </h1> */}
      {/* </div> */}
      <div className="col-sm-12 pr-1">
        <h4 className="project-total mt-2">
          {" "}
          Custom Fields &nbsp;
          <span title="Add Fields">
            <Link
              to={"/custom-task"}
              className="links "
              style={{
                lineHeight: "1.3em",
                color: "rgb(255, 152, 0)",
                fontSize: "20px",
              }}
              onClick={toggleForm}
            >
              <i className="fas fa-plus"></i>
            </Link>
          </span>
        </h4>
      </div>
      {showForm && (
        <ProjectCustomTaskForm
          projects={projects}
          customTasksField={customTasksField}
          setCustomTasksField={setCustomTasksField}
          closeForm={closeForm}
        />
      )}

      <CustomTaskList
        customTasksField={customTasksField}
        setCustomTasksField={setCustomTasksField}
        projectId={"all"}
      />
    </div>
  );
};

export default ProjectCustomTask;
