import React, { Component } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Setup localizer
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date()),
  getDay,
  locales,
});

export default class UserReportsCalendarView extends Component {
  render() {
    const { data } = this.props;

    // Map data to event format for react-big-calendar
    const userReportsData = data.map((d) => ({
      id: d.taskId,
      start: new Date(d.startDate),
      end: new Date(d.endDate),
      title: d.title,
      url: "/project/task/edit/" + d.projectId + "/" + d.taskId,
    }));

    return (
      <div
        id="largeCalendar"
        //className="project-list"
        style={{ marginLeft: 20, width: "100%" }}
      >
        <Calendar
          localizer={localizer}
          events={userReportsData}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={["month", "week", "day"]}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: "#3174ad",
              color: "white",
            },
          })}
          onSelectEvent={(event) => {
            window.location.href = event.url;
          }}
        />
      </div>
    );
  }
}

// import React,{Component} from 'react';
// import FullCalendar from 'fullcalendar-reactwrapper';

// export default class UserReportsCalendarView extends Component {
//     render() {
//             let userReportsData = this.props.data.map((d) => {
//                let info = {
//                    id: d.taskId,
//                    start: d.startDate,
//                    end: d.endDate,
//                    title: d.title,
//                    url: "/project/task/edit/"+d.projectId+"/"+d.taskId
//                }
//                return info;
//             })

//         return(
//         <div id="largeCalendar" className="project-list">
//            <FullCalendar
//                 header = {{
//                     left: 'prev,next today myCustomButton',
//                     center: 'title',
//                     right: 'month,basicWeek,basicDay'
//                 }}
//                 defaultDate= {new Date()}
//                 navLinks= {true} // can click day/week names to navigate views
//                 editable= {true}
//                 eventLimit= {true} // allow "more" link when too many events
//                 events = {userReportsData}
//             />
//         </div>
//         )
//     }
// }
