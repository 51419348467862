import React from "react";
import PropTypes from "prop-types";
import "./task-details-modal.css";
import { format } from "date-fns";
import * as uploadservice from "../../services/upload/upload-service";

const TaskDetailsModal = ({ task, projectId, onClose }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return format(date, "PPPpp");
  };

  const renderCustomFieldValues = (customFieldValues) => {
    return Object.entries(customFieldValues).map(([key, value]) => (
      <div key={key} className="task-detail-item">
        <strong>{key}:</strong> {value}
      </div>
    ));
  };

  const viewFile = async (projectId, taskId, filename) => {
    console.log("Project ID:", projectId);
    console.log("Task ID:", taskId);
    console.log("Filename:", filename);
    try {
      const { response, err } = await uploadservice.viewFile(
        projectId,
        taskId,
        filename
      );
      if (err) {
        console.error("Server failed, file could not be viewed");
        return;
      }
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url);
    } catch (error) {
      console.error("An error occurred while viewing the file:", error);
    }
  };

  // Filter out files that are marked as deleted
  const filteredUploadFiles = task.uploadFiles.filter(
    (file) => !file.isDeleted
  );

  return (
    <div className="task-details-modal">
      <div className="task-details-overlay" onClick={onClose}></div>
      <div className="task-details-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h3>Task Details</h3>
        <div className="task-details-grid">
          <div className="task-detail-item task-info">
            <div>
              <p>Title: {task.title}</p>
              <p>Description: {task.description}</p>
              <p>Completed: {task.completed ? "Yes" : "No"}</p>
              <p>Category: {task.category}</p>
              <p>Tag: {task.tag}</p>
              <p>Status: {task.status}</p>
              <p>Story Point: {task.storyPoint}</p>
              <p>Start Date: {formatDate(task.startDate)}</p>
              <p>End Date: {formatDate(task.endDate)}</p>
              <p>End Date: {formatDate(task.endDate)}</p>
              {/* <p>Department ID: {task.depId}</p> */}
              <p>Task Type: {task.taskType}</p>
              <p>Priority: {task.priority}</p>
              <p>Created On: {formatDate(task.createdOn)}</p>
              <p>Modified On: {formatDate(task.modifiedOn)}</p>
              {/* <p>Created By: {task.createdBy}</p>
              <p>Modified By: {task.modifiedBy}</p>
              <p>Is Deleted: {task.isDeleted ? "Yes" : "No"}</p> */}
              <p>Sequence: {task.sequence}</p>
              <p>
                Messages:{" "}
                {task.messages.length > 0
                  ? task.messages.join(", ")
                  : "No messages"}
              </p>
              <p>
                {" "}
                <strong>Custom Field Values:</strong>
                {renderCustomFieldValues(task.customFieldValues)}
              </p>
            </div>
          </div>

          <div className="task-detail-item upload-files">
            <strong>Upload Files:</strong>
            <ul>
              {filteredUploadFiles.map((file, index) => (
                <li key={index}>
                  <p>Filename: {file.filename}</p>
                  <p>Created On: {formatDate(file.createdOn)}</p>
                  <button
                    onClick={() => viewFile(projectId, task._id, file.filename)}
                  >
                    View File
                  </button>
                  {/* <p>Created By: {file.createdBy}</p> */}
                </li>
              ))}
            </ul>
          </div>
          <div className="task-detail-item subtasks">
            <strong>Subtasks:</strong>
            <ul>
              {task.subtasks.map((subtask) => (
                <li key={subtask._id}>
                  <p>Title: {subtask.title}</p>
                  <p>Completed: {subtask.completed ? "Yes" : "No"}</p>
                  <p>Story Point: {subtask.storyPoint}</p>
                  <p>Sequence: {subtask.sequence}</p>
                  {/* <p>
                    Subtask Hidden Department ID: {subtask.subtaskHiddenDepId}
                  </p> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

TaskDetailsModal.propTypes = {
  task: PropTypes.shape({
    userId: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    completed: PropTypes.bool,
    category: PropTypes.string,
    tag: PropTypes.string,
    status: PropTypes.string,
    storyPoint: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    depId: PropTypes.string,
    taskType: PropTypes.string,
    priority: PropTypes.string,
    createdOn: PropTypes.string,
    modifiedOn: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedBy: PropTypes.string,
    isDeleted: PropTypes.bool,
    sequence: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
    uploadFiles: PropTypes.arrayOf(
      PropTypes.shape({
        isDeleted: PropTypes.bool,
        filename: PropTypes.string,
        createdOn: PropTypes.string,
        createdBy: PropTypes.string,
        _id: PropTypes.string,
      })
    ),
    subtasks: PropTypes.arrayOf(
      PropTypes.shape({
        taskId: PropTypes.string,
        title: PropTypes.string,
        completed: PropTypes.bool,
        edit: PropTypes.bool,
        dateOfCompletion: PropTypes.string,
        isDeleted: PropTypes.bool,
        hiddenUsrId: PropTypes.string,
        storyPoint: PropTypes.number,
        subtaskHiddenDepId: PropTypes.string,
        sequence: PropTypes.number,
        _id: PropTypes.string,
      })
    ),
    customFieldValues: PropTypes.object,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskDetailsModal;
